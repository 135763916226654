<!-- 手机号注册 -->
<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck2">
            <el-form-item style="text-align: center;">
                <span class="form-title">输入企业名称</span>
            </el-form-item>
            <el-form-item prop="comp_name">
                <el-input v-model="ruleForm.comp_name" placeholder="请输入企业名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="ruleForm.comp_short_name" placeholder="请输入企业简称(选填)"></el-input>
            </el-form-item>
            <el-form-item>
                <RegionSelect width="100%" v-model="ruleForm"></RegionSelect>
            </el-form-item>
            <el-form-item>
                <el-input v-model="ruleForm.comp_addr" placeholder="请输入详细地址(选填)"></el-input>
            </el-form-item> 
            <!-- <div style="width:240px;margin:0 auto;" class="flex flex-justify-around">
                <el-button @click="goto(1)" style="width:75px;" size="small" type="primary" plain>上一步</el-button>
                <el-button @click="goto(3)" style="width:75px;" size="small" type="primary">下一步</el-button>
            </div> -->
        </el-form>

    </div>
</template>
<script>
    import RegionSelect from "@/components/region/Index.vue";
    export default {
        components: {
            RegionSelect
        },
        model: {
            prop: "form",
            event: "change"
        },
        props:['form'],
        data() {
            return {
                ruleForm: {
                    comp_name: '',
                    comp_short_name: '',
                    province: '',
                    city: '',
                    county: '',
                    town: '',
                    comp_addr: '',
                },
                rules: {
                    comp_name: [
                        { required: true, message: '请输入企业名称', trigger: 'blur' }
                    ],
                    comp_short_name: [
                        { required: true, message: '请输入企业简称（选填）', trigger: 'blur' }
                    ],
                    comp_city: [
                        { required: true, message: '请输选择地址', trigger: 'blur' }
                    ],
                    comp_addr: [
                        { required: true, message: '请输入详细地址', trigger: 'blur' }
                    ],
                },
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm=this.form;
                },
                deep: true
            },
            ruleForm:{
                handler() {
                    this.onChange();
                },
                deep: true
            }
        },
        mounted() {
            this.ruleForm=this.form;
        },
        methods: {
            onChange() {
                this.$emit('change', this.ruleForm);
            },
            submitForm2() {
                this.$refs['formCheck2'].validate((valid) => {
                    if (valid) {
                        this.$store.state.regStep=3;
                    }
                });
            },
        }
    }

</script>
<style>
    .reg-create-captcha-ipt .el-input-group__append {
        padding: 0 !important;
        overflow: hidden;
    }
</style>
<style scoped>
    .form-title {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
    }

    .captcha-img {
        height: 38px;
        background-size: 100% 100% !important;
        width: 110px;
        cursor: pointer;
    }
</style>
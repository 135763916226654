<template>
  <div class="frame" ref="frame" :class="{ mobile: isMobile }">
    <div class="frame-title">
      <span>欢迎注册家具企业制造执行运营管理系统</span>
      <!-- <span style="float: right;">中国家居产业智联网</span> -->
    </div>
    <div class="step">
      <ItemStep></ItemStep>
    </div>
    <div
      class="reg-frm"
      :class="{ active: activeName == 'create' && $store.state.regStep == 1 }"
    >
      <div>
        <div
          v-if="$store.state.regStep == 1"
          class="flex flex-justify-around login-tab"
        >
          <div
            v-bind:class="{ active: activeName == 'create' }"
            @click="activeName = 'create'"
          >
            创建新企业
          </div>
          <div
            v-bind:class="{ active: activeName == 'join' }"
            @click="activeName = 'join'"
          >
            加入已有企业
          </div>
        </div>
        <!-- <div v-else class="login-tab">
                    <div v-if="$store.state.regStep==2 && activeName=='create'">输入企业名称</div>
                    <div v-if="$store.state.regStep==4">您还可以，邀请更多同事线上使用</div>
                </div> -->
        <div style="margin-top: 20px">
          <Create v-if="activeName == 'create'"></Create>
          <Join v-if="activeName == 'join'"></Join>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Create from "./Create.vue";
import Join from "./Join.vue";
import ItemStep from "./ItemStep.vue";
export default {
  components: {
    Create,
    Join,
    ItemStep,
  },
  data() {
    return {
      activeName: "create",
      // regStep: 组件内注册的步骤,用于判断组件是否在第一步,控制tab部分是否显示
      isMobile: false,
    };
  },
  created() {
    this.$store.state.regStep = 1;
  },
  mounted() {
    this.isMobile = this.$refs["frame"].offsetWidth < 600;
    window.onresize = () => {
      this.isMobile = this.$refs["frame"].offsetWidth < 600;
    };
  },
  methods: {},
  destroyed() {
    window.onresize = null;
  },
};
</script>
<style scoped>
:deep(.el-input__inner) {
  background-color: transparent;
}
:deep(.el-button--primary.is-plain) {
    background-color: transparent;
}
.frame {
  color: #fff;
  background: #0e1423;
  height: 100%;
  overflow: auto;
}
.frame-title {
  font-size: 24px;
  font-weight: bold;
  width: 630px;
  margin: 80px auto 50px;
  overflow: hidden;
  text-align: center;
  color: #fff;
}

.reg-frm {
  margin: 0 auto 30px;
  width: 600px;
  background: #192b54;
  border-radius: 20px;
  overflow: hidden;
}
.reg-frm.active {
  width: 1200px;
}

.reg-frm > div {
  /* width: 254px; */
  margin: 30px auto;
}
.reg-frm.active > div {
  width: 100%;
}

.reg-frm.active .login-tab {
  width: 400px;
  margin: 0 auto;
}
.reg-frm .login-tab {
  width: 254px;
  margin: 0 auto;
}
.login-tab > div {
  cursor: pointer;
  font-size: 16px;
  padding: 8px 0;
}
.login-tab > div.active {
  color: #24b1eb;
  border-bottom: 2px solid #24b1eb;
}
.frame.mobile .frame-title {
  display: none;
}
.frame.mobile .step {
  display: none;
}
.frame.mobile .reg-frm {
  width: auto;
  box-shadow: none;
}
</style>